import { version } from '$app/environment';
import { PUBLIC_SENTRY_FRONTEND_KEY } from '$env/static/public';
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
    dsn: PUBLIC_SENTRY_FRONTEND_KEY,
    release: version,
    environment: import.meta.env.DEV ? "dev" : "prod",

    // Performance Monitoring
    //tracesSampleRate: import.meta.env.DEV ? 1 : 0.1, // Capture 100% of the transactions, reduce in production!

    // Optional: Initialize Session Replay:
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
});

export async function handleError({ error, event }) {
    Sentry.handleErrorWithSentry();

    return {
        message: 'Whoops!',
    };
}
